<template>
  <div>
    <b-card-group deck>
      <notifications group="alert" position="top center" />
      <b-card header-tag="header" footer-tag="footer">
        <template v-slot:header>
          <h6 class="mb-0">Tài khoản ngân hàng đảm bảo</h6>
        </template>

        <b-row>
          <b-col>
            <b-form-group label="Tên viết tắt">
              <b-form-input v-model="filter.bank_code"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Tên đầy đủ">
              <b-form-input v-model="filter.bank_name"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select v-model="filter.status" :options="status"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="actions text-right">
              <b-button class="mr-2" variant="outline-info" @click="listBankMaintenance(1)">Tìm kiếm</b-button>
            </div>
          </b-col>
        </b-row>
        <hr>

        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{(currentPage - 1) * 25 + (data.index + 1)}}
          </template>
          <template #cell(bank_balance)="data">
            {{ numberFormat(data.item.bank_balance) }}
          </template>
          <template #cell(status)="data">
            {{ convertStatus(data.item.status) }}
          </template>
          <template #cell(action)="data">
            <b-button-group class="btn-group-sm">
              <b-button variant="success" @click="showModal(data.item)">Sửa</b-button>
            </b-button-group>
          </template>

        </b-table>
        <div class="mt-3">
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card>
    </b-card-group>

    <b-modal id="my-modal" size="lg" hide-footer ref="myModal" :class="['modal-lg']" title="Cập nhật số dư" v-if="item">
      <b-row>
        <b-col><span class="d-inline-block w-25 text-right mr-1">Tên ngân hàng: </span><strong>{{ item.bank_name }}</strong></b-col>
      </b-row>
      <b-row>
        <b-col><span class="d-inline-block w-25 text-right mr-1">Mã ngân hàng: </span><strong>{{ item.bank_code }}</strong></b-col>
      </b-row>
      <b-row>
        <b-col><span class="d-inline-block w-25 text-right mr-1">Số dư hiện tại: </span><strong>{{ numberFormat(item.bank_balance) }}</strong></b-col>
      </b-row>
      <b-row>
        <b-col><span class="d-inline-block w-25 text-right mr-1">Cập nhật lúc: </span><strong>{{ item.updated_at }}</strong></b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group label="Số dư">
            <b-form-input v-model="item_update.bank_balance" @keydown="numbersOnly($event, true)"></b-form-input>
            <span class="valid-feedback d-block" v-if="item_update.bank_balance > 0">{{ numberFormat(parseInt(item_update.bank_balance)) }} VNĐ</span>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <div class="text-center">
            <b-button class="btn-success mr-2" @click="update(item_update.id)">Cập nhật</b-button>
            <b-button class="btn-danger" @click="hideModal()">Hủy</b-button>
          </div>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>

<script>
import Vue from "vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Notifications from "vue-notification";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import Common from "@/core/mixins/common";

const CmsRepository = RepositoryFactory.get("cms");
Vue.use(Notifications);

export default {
  mixins: [Common],
  data() {
    return {
      filter: {
        status: null,
        bank_code: null,
        bank_name: null
      },
      items: [],
      item: {},
      item_update: {
        id: null,
        bank_balance: null
      },
      fields: [
        { index: "STT" },
        { bank_code: "Tên viết tắt" },
        { bank_name: "Ngân hàng" },
        { account_no: "Số tài khoản" },
        { bank_balance: "Số dư" },
        { status: "Trạng thái" },
        { created_at: "Ngày tạo" },
        { updated_at: "Ngày cập nhật" },
        { action: "Hành động" },
      ],
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      status: [
        { text: "Hoạt động", value: 1 },
        { text: "Không hoạt động", value: -1 },
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Tài khoản ngân hàng đảm bảo", route: "tools/bank/monitor" },
    ]);
  },
  methods: {
    listBankMaintenance(page = 1) {
      this.items = [];
      let params = { ...this.filter };
      params = this.convert(params);
      params.page = page;
      this.$bus.$emit("show-loading", true);
      CmsRepository.listBankMonitor(params)
      .then((response) => {
        if (response.data.error_code) {
          this.notifyAlert("warn", response.data.message);
          return;
        }

        this.items = response.data.data.data;
        this.paginate.total = response.data.data.total;
        this.paginate.totalPage = response.data.data.last_page;
        this.paginate.currentPage = response.data.data.current_page;
        this.$bus.$emit("show-loading", false);
      })
      .catch(() => {
        this.$bus.$emit("show-loading", false);
        alert("Có lỗi xảy ra");
      });
    },
    showModal(item) {
      this.item = item;
      this.item_update.id = item.id;
      this.$bvModal.show("my-modal");
    },
    hideModal() {
      this.item = {};
      this.item_update.id = null;
      this.item_update.bank_balance = null;
      this.$bvModal.hide("my-modal");
    },
    update(id) {
      let params = this.item_update;
      params = this.convert(params);
      this.$bus.$emit("show-loading", true);
      CmsRepository.updateBankMonitor(id, params)
          .then((response) => {
            if (response.data.error_code) {
              this.notifyAlert("warn", response.data.message);
              return;
            }

            this.notifyAlert("success", "Cập nhật thành công!");
            window.location.reload();
            this.$bus.$emit("show-loading", false);
          })
          .catch(() => {
            this.$bus.$emit("show-loading", false);
            alert("Có lỗi xảy ra");
          });
    },
    convertStatus(status) {
      let result = status;
      let listStatus = this.status;

      listStatus.forEach(function(item) {
        if (item.value === status) {
          result = item.text;
        }
      });

      return result;
    },
    convert(params) {
      for (let key in params) {
        if (params[key] == "") {
          delete params[key];
        }
      }
      return params;
    },
  },
  watch: {
    item_update: {
      deep: true,
      handler(newVal) {
        if (newVal.bank_balance) {
          let balance = newVal.bank_balance;
          newVal.bank_balance = balance.replace(/\./g, "");
          this.item_update.bank_balance = newVal.bank_balance;
        }
      }
    }
  },
  created() {
    this.listBankMaintenance();
  },
};
</script>
